<template>
    <h1>SOBRE NOSOTROS</h1>    
</template>

<script>  
export default {  
  name: "App",  
  mounted() {  
    document.title = "Covalent - Quienes Somos";  
  },  
};  
</script>