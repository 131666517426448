<template>
    <h1>NOT FOUND</h1>    
</template>

<script>  
export default {  
  name: "App",  
  mounted() {  
    document.title = "Covalent - 404 Página no encontrada";  
  },  
};  
</script>