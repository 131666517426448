<template>
    <section class="benefits">
        <div class="container">
            <div class="title">
                <h2>Beneficios de Covalent</h2>
                <p>Nuestros principios básicos de servicio, son estos:</p>
            </div>
            <div class="key__values">
                <ul>
                    <li>
                        <i class="icon adaptable_technology"></i>
                        <h3>Tecnología Adaptable</h3>
                        <p>Nos adaptamos a tus necesiades con total libertad</p>
                    </li>
                    <li>
                        <i class="icon security"></i>
                        <h3>Máxima seguridad</h3>
                        <p>Usamos la tecnología más actual para gartantizar la seguridad de tu proyecto</p>
                    </li>
                    <li>
                        <i class="icon customer_service"></i>
                        <h3>Atención personalizada</h3>
                        <p>Sabemos que no todos nuestros clientes tienen la misma necesidad, por eso personalizamos todas nuestras propuestas</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<style scoped>
.benefits{ background-color: #285B6E; }

.container { padding: 76px 105px; margin: 0 auto; max-width: 1440px; }
.container .title{ text-align: center; }
.container .title h2{ color: #FFFFFF; font-size: 48px; line-height: 64px; font-weight: 800; }
.container .title p{ color: #FFFFFF; font-size: 18px; line-height: 32px; margin-bottom: 48px; max-width: 540px; display: inline-block; }

.container .key__values ul { list-style: none; display: grid; grid-template-columns: repeat(3,1fr); grid-gap: 30px; text-align: center; }
.container .key__values ul li h3{ color: #FFFFFF; font-size: 24px; line-height: 32px; margin-bottom: 8px; }
.container .key__values ul li p { color: #FFFFFF; font-size: 16px; line-height: 26px; }

i.icon { display: inline-block; width: 32px; height: 32px; margin-bottom: 24px; }
i.adaptable_technology { background: url("../../assets/icons/technology.png"); }
i.security { background: url("../../assets/icons/security.png"); }
i.customer_service { background: url("../../assets/icons/customer.png"); }

@media (max-width: 850px) {
    .container { padding: 50px; }
    .container .key__values ul { grid-template-columns: repeat(1,1fr); }
    .container .key__values ul li h3{ font-size: 18px; }
    .container .key__values ul li p { font-size: 14px; }
}

@media (max-width: 648px) {
    .container .title h2 { font-size: 28px; }
    .container .title p{ font-size: 14px; }
}

@media (max-width: 550px) { 
    .container .title h2 { font-size: 24px; line-height: 32px; margin-bottom: 5px; }
    .container { padding: 20px; }
    .container .title p{ margin-bottom: 15px; }
 }

</style>