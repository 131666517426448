export default {
  "nav|about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quienes Somos"])},
  "nav|services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
  "nav|contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
  "nav|login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar Sesión"])},
  "top_slider|see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "services|know_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saber más"])},
  "footer|all_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los derechos reservados"])},
  "footer|privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
  "contact|ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje enviado correctamente."])},
  "contact|ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error, por favor intentalo más tarde."])}
}