<template>
    <div class="topbar menu">
        <nav class="grid">
            <div class="logo">
                <router-link class="header__link" to="/"><img src="../../assets/covalent.png" alt="COVALENT"></router-link >
            </div>
            <div class="links" v-bind:class="[isActive ? '' : 'open']">
                <ul>
                    <li><router-link class="header__link" to="/services" @click="toggleClass()">{{ $t('nav|services') }}</router-link ></li>
                    <li><router-link class="header__link" to="/contact" @click="toggleClass()">{{ $t('nav|contact') }}</router-link ></li>
                </ul>
            </div>
        </nav>
        <div class="menu-toggle" v-bind:class="[isActive ? '' : 'open']" @click="toggleClass()">
            <i></i>
        </div>
    </div>
</template>


<script>
 
export default {
  name: "App",

  data() {
    return {
        isActive: true,
    };
  },
  methods: {
    toggleClass: function(){
        this.isActive = !this.isActive;
    }
  }
 
};


</script>


<style scoped>
    .topbar { width: 100%; height: 60px; max-height: 60px; box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25); padding: 0 84px; margin-bottom: 11px; }
    .topbar.menu .grid { display: grid; height: 100%; grid-template-columns: repeat(2,1fr); justify-content: space-evenly; }
    .topbar.menu .grid .logo,
    .topbar.menu .grid .links { align-self: center; }
    .topbar.menu .grid .links ul { list-style: none; text-align: right; }
    .topbar.menu .grid .links ul li { display: inline-block; margin-left: 27px; }
    .topbar.menu .grid .links ul li a.header__link { display: block; color: #275A6D; text-decoration: none; font-size: 15px; line-height: 20px; font-weight: bold; margin-bottom: 2px; border-bottom: 2px solid #FFF; }
    .topbar.menu .grid .links ul li a.header__link.button { background: #1967AE; display: block; color: #fff; border: 2px solid #1967AE; border-radius: 5px; padding: 8px; }
    .topbar.menu .grid .links ul li a.header__link:hover { border-bottom: 2px solid #275A6D; }
    .topbar.menu .grid .links ul li a.header__link.button:hover {  border: 2px solid #1967AE; background: #FFF; color: #1967AE; }

    .menu-toggle {
        display: none;
        height: 32px;
        position: absolute;
        top: 10px;
        right: 20px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        width: 40px;
    }
    .menu-toggle:hover { color: #275A6D; }
    .menu-toggle i,
    .menu-toggle i:after,
    .menu-toggle i:before {
        display: block;
        position: absolute;
        height: 2px;
        width: 40px;
        right: 0;
        background: #275A6D;
        border-radius: 2px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }
    .menu-toggle i:after,
    .menu-toggle i:before { content: ''; }
    .menu-toggle i:after { top: -32px; }
    .menu-toggle i:before { top: -16px; }
    .menu-toggle i { top: 32px; }
    .menu-toggle.open i:after {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -22px;
    }
    .menu-toggle.open i:before {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: -22px;
    }

    @media (max-width: 550px) { 
        .topbar.menu .grid { grid-template-columns: repeat(1,1fr); justify-items: center; }
        .links { display: none; }
        .links.open { display: inline-block;
            width: 100%;
            background: #FFF;
            position: absolute;
            left: 0;
            top: 60px;
            z-index: 9;
            opacity: 0;
            animation: fadeIn 1s ease-in both;
        }
        .topbar.menu .grid .links.open ul li { display: block; margin-top: 20px; margin-bottom: 20px; text-align: center; }
        .menu-toggle { display: inline-block; }

        .topbar.menu .grid .links ul li a.header__link:hover { border-bottom: 2px solid #FFF; }

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: translate3d(0, -20%, 0);
            }
            to {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }
</style>