<template>
    <section class="top__services">
        <h1>Nuestros servicios</h1>
        <p>A continuación listamos nuestros servicos más TOP.</p>
        <p>De todas formas, nos adaptamos 100% a tu proyecto y tus necesidades</p>
    </section>
    <section class="bkg__blue">
        <div class="container">
            <div class="text_left grid">
                <div class="text txt__white">
                    <h3>Servicio de conectividad</h3>
                    <p>¿Quieres conectar tus dispositivos IoT en una única plataforma y tener control sobre estos? Este es tu servicio</p>
                    <p><router-link class="button bgk__white txt__blue" to="/contact">{{ $t('services|know_more') }}</router-link ></p>
                </div>
                <div class="image">
                    <img src="../../assets/images/code_bkg.png" alt="code 2">
                </div>
            </div>
        </div>
    </section>
    <section class="bkg__white">
        <div class="container">
            <div class="text_right grid">
                <div class="text txt__black">
                    <h3>Desarrollo en solución digital vertical</h3>
                    <p>¿Eres una empresa y quieres tener control sobre los dispositivos que tienes en producción? Haznoslo saber</p>
                    <p><router-link class="button bgk__green txt__white" to="/contact">{{ $t('services|know_more') }}</router-link ></p>
                </div>
                <div class="image">
                    <img src="../../assets/images/laptop_bkg.png" alt="code 2">
                </div>
            </div>
        </div>
    </section>
    <section class="bkg__green">
        <div class="container">
            <div class="text_left grid">
                <div class="text txt__white">
                    <h3>Hosting de aplicaciones</h3>
                    <p>Servicio 100% diseñado para aquellas aplicaciones que quieres poner en producción y no tienes el conocimiento para hacerlo</p>
                    <p><router-link class="button bgk__white txt__blue" to="/contact">{{ $t('services|know_more') }}</router-link ></p>
                </div>
                <div class="image">
                    <img src="../../assets/images/code2_bkg.png" alt="code 2">
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.container{
    max-width: 1440px;
    margin: 0 auto;
}
.top__services{
    text-align: center;
    padding: 69px;
}
.top__services h1{
    font-size: 40px;
    font-weight: bold;
    color: #18191F;
    padding-bottom: 16px;
}
.top__services p{
    font-size: 16px;
    color: #18191F;
    line-height: 24px;
}
.bkg__blue{
    background-color: #1967AE;
    padding: 69px;
}
.bkg__white{
    background-color: #FFFFFF;
    padding: 69px;
}
.bkg__green{
    background-color: #285B6E;
    padding: 69px;
}

.txt__white{
    color: #FFFFFF;
}

.txt__black{
    color: #000000;
}

.text {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
}

.text p {
    margin-bottom: 40px;
}

.text h3{
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 40px;
}

.text_right .text { order: 2 }

.grid{
    display: grid; 
    grid-template-columns: repeat(2,1fr); 
    grid-column-gap: 72px;
    justify-content: space-evenly;
}

.button { border-radius: 5px; font-size: 16px; font-weight: 700; line-height: 30px; padding: 16px 48px; text-decoration: none; }
.button.txt__blue { color: #1967AE; }
.button.bgk__white { background-color: #FFFFFF; }

.button.bgk__green { background-color: #285B6E; }
.button.txt__white { color: #FFFFFF; }

@media (max-width: 1080px) {
    .container .grid { grid-template-columns: repeat(1,1fr);  }
    .container .grid .image { display: none; }
}
</style>