<template>
    <section class="top_slider">
        <div class="container">
            <h1>Somos tu facilitador de Tecnología y Data</h1>
            <router-link class="button" to="/services">{{ $t('top_slider|see_more') }}</router-link >
        </div>
    </section>
</template>

<style scoped>
.top_slider{
    background: url("../../assets/images/bkg_slider.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 960px;
    margin-top: -11px;
}

.container {
    padding-left: 100px;
    padding-top: 250px;
}
.container h1{
    font-weight: 800;
    font-size: 72px;
    line-height: 75px;
    color: #FFFFFF;
    width: 740px;
}
.button {
    border-radius: 4px;
    background: #FFFFFF;
    color: #285B6E;
    font-size: 16px;
    line-height: 30px;
    font-weight: 700;
    padding: 12px 52px;
    margin-top: 77px;
    text-decoration: none;
    display: inline-block;
}

@media (max-width: 1080px) {
    .container h1{ font-size: 52px; width: 100%; }
}
@media (max-width: 1268px) {
    .top_slider{ background-size: cover; height: auto; }
}
@media (max-width: 768px) {
    .top_slider{ background-size: cover; height: auto; }
    .container h1{ width: 100%; }
    .container { padding-left: 25px; padding-top: 50px; padding-bottom: 50px; }
    .button { margin-top: 17px } 
}
@media (max-width: 555px) {
    .container h1{ font-size: 32px; line-height: 32px; width: 100%; }

}
@media (max-width: 450px) {
    .container h1{ font-size: 24px; line-height: 32px; width: 100%; }
    .button { padding: 7px 22px; font-size: 14px; }
}
</style>