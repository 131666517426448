<template>
    <div class="bkg__grey">
        <section class="top__services">
            <h1>¡Contáctanos!</h1>
            <p>¿Tienes alguna duda?. Pónte en contacto con nosotros y resolvamos todas las dudas.</p>
        </section>
        <section class="contact__form">
            <div class="content">
                <h2>¡Cuéntanos tu idea!</h2>
                <p>Déjanos un mensaje</p>
                <div class="form">
                    <form ref="form" @submit.prevent="sendEmail">
                        <input class="input" type="text" placeholder="Nombre" name="name" required>
                        <input class="input" type="email" placeholder="Email" name="email" required>
                        <textarea name="message" placeholder="Escribe tu mensaje" id="" cols="70" rows="30" required></textarea>
                        <div class="global_message" :class="{'ok': isSent}">
                            <span>{{ $t('contact|ok') }}</span>
                        </div>
                        <input type="submit" class="button" value="Enviar" v-on:click="sentForm">
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    data() {
        return {
            isSent: false,
        };
    },
    methods: {
        sentForm: function() {
            this.isSent = true;
        },
        sendEmail(event) {
            emailjs.sendForm('service_k0263ms', 'template_9oaaw6q', this.$refs.form, 'user_iluQxgXRKKZNRF6Fk8vi4')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
            event.target.reset();
        }
    }
}

</script>


<style scoped>

.global_message { margin-bottom: 20px; padding: 0 10px; font-size: 14px; line-height: 32px; display: none; }
.global_message.ok { border: 1px solid #00a200; background: #ddedd6; color: #174710; display: block; }
.global_message.ko { border: 1px solid #ea1b1e; background: #fcbfb6; color: #d53032; display: block; }
.top__services{
    text-align: center;
    padding: 69px;
    margin: 0 auto;
    background-color: #FAFAFA;
}
.top__services h1{
    font-size: 40px;
    font-weight: bold;
    color: #18191F;
    padding-bottom: 16px;
}
.top__services p{
    font-size: 16px;
    color: #18191F;
    line-height: 24px;
}
.contact__form {
    background-color: #FAFAFA;
    padding-bottom: 100px;
}
.contact__form .content{
    max-width: 1156px;
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 2px 10px 28px rgba(0, 48, 135, 0.12);
    border-radius: 10px;
    padding: 82px;
}
.contact__form .content h2{
    color: #4A99D3;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
}
.contact__form .content h2::after{
    contain: "";
    width: 100px;
    height: 1px;
    display: inline-block;
    border-bottom: 1px solid #4A99D3;
}

.contact__form .content p {
    color: #0F001A;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 51px;
}

.contact__form .content .form .input,
.contact__form .content .form textarea{
    display: inline-block;
    width: 100%;
    border: 1px solid #B4BEC8;
    border-radius: 4px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 25px;
    padding-left: 17px;
}

.contact__form .content .form textarea{
    resize: none;
    height: 300px;
}


.button { background-color: #1967AE; border-radius: 5px; font-size: 16px; font-weight: 700; line-height: 30px; padding: 16px 48px; color: #FFFFFF; width: 300px; }

@media (max-width: 600px) {
    .top__services h1{ font-size: 24px; }
    .top__services{ padding: 20px; }
    .top__services p { font-size: 14px; }
    .contact__form .content h2{ display: none; }
    .contact__form .content { padding: 20px; }
    .button { padding: 16px 0; width: 100%; }
}
</style>