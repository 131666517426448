<template>
    <section class="privacy_policy">
        <h1>Política de Privacidad</h1>
        <div class="content">
            <p class="first">Covalent (en adelante COVALENT) es una marca registrada de Marc Filbà Gallego. que considera como objetivo básico garantizar la privacidad y confidencialidad de los datos de carácter personal, cumpliendo con el Reglamento General de Protección de Datos (RGPD). Esta política de privacidad de datos tiene como objetivo que puedas consultar de forma rápida y clara cómo utilizamos los datos personales que tenemos sobre ti.</p>
            <h2>1.- ¿Cómo hacemos el Tratamiento de Datos?</h2>
            <p>Para acceder a y/o utilizar los servicios de COVALENT es necesario que nos proporciones previamente ciertos datos de carácter personal. De conformidad con lo establecido en la Ley Orgánica 15/1999 de Protección de Datos (LOPD) de Carácter Personal te informamos que los datos recogidos durante la navegación y compra se incorporarán a un fichero registrado en la Agencia Española de Protección de Datos, del que es responsable Marc Filbà Gallego. COAVALENT garantiza que ha adoptado las medidas oportunas de seguridad, como cortafuegos, tecnologías de cifrado y contraseñas para prevenir y evitar usos no autorizados de datos personales. Además, COAVALENT garantiza la confidencialidad de tus datos personales y que éstos no serán cedidos ni vendidos a terceras empresas. En todo caso COAVALENT revelará a la autoridad competente los datos personales y cualquier otra información que esté en su poder o sea accesible a través de sus sistemas y sea requerida de conformidad con las disposiciones legales aplicables.</p>
            <h2>2.- ¿Quién es el Responsable del Fichero de Datos?</h2>
            <p>La información que nos facilitas a través de nuestros formularios o los datos que rellenas para recibir los servicios, serán incluidos en el fichero de datos responsabilidad de Marc Filbà Gallego. El responsable de seguridad y protección de datos es la misma empresa COVALENT. Para cualquier duda o consulta acerca de la finalidad de los datos que almacenamos, modificación o cancelación de estos, puedes contactar con nosotros en el correo electrónico: <a href="mailto:hola@covalentapp.com" target="_blank" rel="noopener">hola [at] covalentapp dot com</a></p>
            <h2>3.- ¿Qué Datos Personales obtenemos y qué hacemos con ellos?</h2>
            <p>Para que puedas utilizar nuestros servicios utilizamos algunos datos de ti que obtenemos de diferentes formas y en diferentes momentos: Utilizaremos los Datos Personales que tú mismo nos das al rellenar el formulario de contacto.</p>
            <h2>4. ¿Para qué utilizamos tus Datos Personales?</h2>
            <p>Utilizamos tus Datos Personales para los siguientes casos:</p>
            <p>4.a. Prestación del Servicio · Gestión la creación de tu cuenta de usuario y/o modificarla.</p>
            <p>· Permitirte acceder a un histórico de tus compras.</p>
            <p>· Facilitarte el acceso por email o desde tu cuenta a las facturas de tus pedidos.</p>
            <p>4.b. Realizar Acciones de Marketing · Utilizamos tus datos para enviarte noticias relacionadas con nuestros servicios y ofertas de COVALENT.</p>
            <p>· En ningún caso te enviaremos comunicaciones de otras empresas o productos que no tengan nada que ver con la actividad de COVALENT.</p>
            <p>· Ten en cuenta que puedes darte de baja de estas comunicaciones en cualquier momento. Tan sólo tendrás que acceder a tu cuenta modificar esta preferencia.</p>
            <p>4.c. Mejorar el Servicio que Prestamos · Trabajamos en la mejora constante de nuestras plataformas, en la experiencia de navegación tanto desde ordenador como desde dispositivos móviles.</p>
            <h2>5.- ¿Durante cuánto tiempo utilizaremos tus Datos Personales?</h2>
            <p>El nuevo Reglamento de Protección de Datos establece un periodo de conservación de Datos Personales en cada una de las finalidades para las que los utilizamos</p>
            <p>5.a. Prestación del Servicio<br>Utilizaremos tus Datos Personales hasta que decidas dejar de utilizar nuestro Servicio. Para esto deberás eliminar tu cuenta de usuario. Mientras no te des de baja de nuestro Servicio, continuaremos tratando tus Datos Personales.</p>
            <p>5.b. Realizar Acciones de Marketing<br>Utilizaremos tus Datos Personales para los fines de marketing expuestos en el apartado (4.b.) mientras hayas aceptado recibir las comunicaciones. Dejaremos de usarlos cuando hayas entrado en tu cuenta y desmarcado la casilla para dejar de recibir nuestras comunicaciones.</p>
            <p>5.c. Mejorar el Servicio que Prestamos<br>Utilizaremos tus Datos Personales hasta que decidas dejar de utilizar nuestro Servicio. Para esto deberás eliminar tu cuenta de usuario. Mientras no te des de baja de nuestro Servicio, continuaremos tratando tus Datos Personales.</p>
            <h2>6.- ¿Compartiremos tus Datos Personales con terceros?</h2>
            <p>No compartiremos tus Datos Personales con ninguna empresa con el objetivo de usar estos datos en acciones de publicidad y marketing.</p>
            <h2>7.- ¿Qué derechos tienes en materia de Protección de Datos?</h2>
            <p>Como usuario de COVALENT tienes una serie de derechos para que puedas decidir y controlar en todo momento cómo utilizamos tus Datos Personales. Puedes ejercer estos derechos en cualquier momento y debes hacerlo a través del correo electrónico: <a href="mailto:hola@covalentapp.com" target="_blank" rel="noopener">hola [at] covalentapp dot com</a> para que podamos atenderte. Dispones de los siguientes derechos:</p>
            <p>7.a. Acceso y Rectificación<br>Puedes pedirnos que te digamos si estamos utilizando tus Datos Personales y qué datos tenemos sobre ti, para qué los estamos usando y el periodo de tiempo que vamos a conservarlos. También puedes pedirnos que modifiquemos alguno de los datos que tenemos sobre ti para que estén actualizados.</p>
            <p>7.b. Derecho de Supresión (derecho al olvido) <br>Puedes solicitarnos que eliminemos los Datos Personales que tenemos sobre ti. Por ejemplo, si te has dado de baja de nuestro servicio eliminado tu cuenta, ya no es necesario que sigamos tratando tus Datos Personales para ningún efecto.</p>
            <p>7.c. Derecho de Oposición <br>Tienes derecho a oponerte a que utilicemos tus datos para enviarte comunicaciones promocionales.&nbsp;</p>
            <p>7.d. Derecho de Limitación <br>Puedes pedirnos que limitemos temporalmente el tratamiento de tus Datos Personales, cuando estos no sean correctos y tengamos que confirmarlos. También cuando ya no los necesitemos, pero prefieras que los conservemos para acceder a tu historial o facturas.</p>
            <p>7.e. Portabilidad <br>Tienes derecho a la portabilidad de tus datos. Puedes pedirnos que te entreguemos, siempre que sea técnicamente posible, un archivo estructurado, de uso común y legible por un sistema informático, que contenga información sobre los pedidos que has realizado y los Datos Personales que almacenamos sobre ti.</p>
            <p>7.f. Reclamación <br>Puedes presentar una reclamación relativa a cómo utilizamos tus datos personales, primero contactando con nuestro responsable de protección de datos (en el punto 2 de esta Política de Privacidad).</p>
            <p>Pero también puedes presentar una reclamación ante la autoridad de control competente en materia de protección de datos. En este caso, en España es: Agencia de Protección de Datos · C/ Jorge Juan 6, 28001 · Madrid.</p>
            <h2>8.- Actualización y Modificación</h2>
            <p>COVALENT se reserva el derecho a actualizar o modificar la información contenida en la presente Política de Privacidad con objeto de adaptarlas a las posibles novedades legislativas, o por motivos estratégicos.</p>
            <p>Ten en cuenta que, si por algún motivo hacemos algún cambio en esta Política de Privacidad, te informaremos puntual y detalladamente antes de que los cambios comiencen a ser aplicables.</p>
            <h2>9.- Legislación y Jurisdicción Aplicables</h2>
            <p>Con carácter general, las relaciones que se deriven de la prestación de los servicios contenidos en nuestra tienda online portal están sometidas a la legislación y la jurisdicción española.</p>
            <p>Para la resolución de problemas o preguntas relacionadas con este sitio Web, por favor póngase en contacto con COVALENT por medio de nuestro correo electrónico: <a href="mailto:hola@covalentapp.com" target="_blank" rel="noopener">hola [at] covalentapp dot com</a></p>
            <p>Estas Condiciones Generales se rigen por la ley española. Las partes se someten, a su elección, para la resolución de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del usuario.</p>
            </div>
    </section>
</template>

<style scoped>

.privacy_policy{ padding: 70px; background-color: #FAFAFA; }
.privacy_policy h1 { font-size: 40px; font-weight: bold; color: #18191F; padding-bottom: 16px; text-align: center; }
.privacy_policy h2 {font-size: 24px; font-weight: bold; color: #18191F; padding-bottom: 16px; }
.privacy_policy p { font-size: 14px; color: #18191F; line-height: 24px; margin-bottom: 12px; margin-left: 24px; }
.privacy_policy p.first { margin-left: 0;}

.privacy_policy .content{
    max-width: 1156px;
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 2px 10px 28px rgba(0, 48, 135, 0.12);
    border-radius: 10px;
    padding-top: 40px;
    padding-bottom: 40px; 
    padding-left: 60px; 
    padding-right: 60px;
}

@media (max-width: 1080px) { 
    .privacy_policy .content{ padding: 40px; }
}

@media (max-width: 600px) { 
    .privacy_policy h1 { font-size: 24px; }
    .privacy_policy h2 {font-size: 18px; }
    .privacy_policy{ padding: 20px; }
    .privacy_policy .content{ padding: 15px; }
    .privacy_policy p { margin-left: 0; font-size: 13px; }
}

</style>