import { createWebHistory, createRouter } from 'vue-router';
import Home from './views/home';
import AboutUs from './views/about_us.vue';
import Services from './views/services.vue';
import Contact from './views/contact.vue';
import PrivacyPolicy from './views/privacy_policy.vue';
import Notfound from './views/not_found.vue';

const routes = [
    { 
        path: '/', 
        component: Home 
    },
    { 
        path: '/about-us', 
        component: AboutUs 
    },
    { 
        path: '/services', 
        component: Services 
    },
    { 
        path: '/contact', 
        component: Contact 
    },
    { 
        path: '/privacy_policy', 
        component: PrivacyPolicy 
    },
    { 
        path: '/:catchAll(.*)', 
        component: Notfound 
    }
];

const router = createRouter({
    routes,
    history: createWebHistory()
});

export default router;