<template>
    <ContactComponent />
</template>

<script>  
// Imports VUE
import ContactComponent from '../components/contact/contact_us.vue';


export default {  
  name: "App",
  components: {
      ContactComponent
  },
  mounted() {  
    document.title = "Covalent - Contacto";  
  },  
};  
</script>