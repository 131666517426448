<template>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <div>
        <footer class="container">
            <div class="links">
                <ul>
                    <li><router-link class="header__link" to=”/services>{{ $t('nav|services') }}</router-link ></li>
                    <li><router-link class="header__link" to=”/contact>{{ $t('nav|contact') }}</router-link ></li>
                </ul>
            </div>
            <div class="inner-footer">
                <div class="left">
                    <p>Copyright © 2021. {{ $t('footer|all_rights') }}</p>
                    <p><router-link class="header__link" to="/privacy_policy">{{ $t('footer|privacy_policy') }}</router-link ></p>
                </div>
            </div>
        </footer>
    </div>
</template>

<style scoped>
    footer.container { border-top: 1px solid #1967AE; }
    .container .links { list-style: none; text-align: left; padding: 38px 67px 0 67px; }
    .container .links ul { margin-bottom: 120px; }
    .container .links ul li { display: inline-block; margin-right: 27px; }
    .container .links ul li a { display: block; color: #1967AE; text-decoration: none; text-decoration: none; font-size: 15px; line-height: 20px; font-weight: bold; margin-bottom: 2px; border-bottom: 2px solid #FFF;  }
    .container .links ul li a:hover { border-bottom: 2px solid #1967AE; }
    .container .inner-footer { background: #1967AE; width: 100%; display: grid; grid-template-columns: repeat(2,1fr); justify-content: space-evenly; height: 41px; padding: 0 61px; }
    .container .inner-footer .left,
    .container .inner-footer .right { align-self: center; }
    .container .inner-footer .left p { display: inline-block; margin-right: 35px; color: #FFF; font-size: 12px; line-height: 15px; }
    .container .inner-footer .left p a { color: #FFF; text-decoration: none; }
    .container .inner-footer .left p a:hover { text-decoration: underline; }
    .container .inner-footer .right ul {list-style: none; text-align: right; }
    .container .inner-footer .right ul li { display: inline-block; margin-right: 27px; }
    .container .inner-footer .right ul li a { color:#FFF; text-decoration: none; }

    @media (max-width: 9999px) {
        footer.container { border: 0 none; }
        .container .links{ display: none; }
    }

    @media (max-width: 1080px) {
        .container .inner-footer { grid-template-columns: repeat(1,1fr); }
    }
    @media (max-width: 600px) {
        .container .inner-footer  { padding-top: 12px; padding-bottom: 12px; height: auto; }
        .container .inner-footer .left p { display: block; margin-right: 0; text-align: center; line-height: 19px; }
    }
</style>