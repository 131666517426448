<template>
  <Navigation />
  <router-view />
  <Footer />
</template>

<script>
// Imports VUE
import Navigation from './components/includes/nav.vue';
import Footer from './components/includes/footer.vue';

//Imports CSS
import ('./assets/css/reset.css');

// Create App
export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  }
}
</script>

<style>
/* Imports Google Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Roboto:wght@700&display=swap');

/* Global Styles */
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
</style>