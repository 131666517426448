<template>
    <PrivacyPolicy />
</template>

<script>  
// Imports VUE
import PrivacyPolicy from '../components/privacy_policy/policy.vue';


export default {  
  name: "App",
  components: {
      PrivacyPolicy
  },
  mounted() {  
    document.title = "Covalent - Política de Privacidad";  
  },  
};  
</script>