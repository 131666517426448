<template>
    <section class="what__they__said">
        <div class="container">
            <div class="title">
                <h4>¿Qué dicen nuestros clientes sobre Covalent?</h4>
            </div>
            <div class="key__reviews">
                <div class="bkg__reviews">
                    <img src="../../assets/images/bkg_reviews.png" alt="Customer Reviews">
                </div>
                <div class="txt__reviews">
                    <div class="reviews">
                        <div id="review1" class="content">
                            <p>“Covalent está ayudando a la gente que no necesariamente tienen las habilidades de informática a conectar directamente con sus consumidores. Veo eso como un verdadero cambio respecto a las oportunidades disponibles.”</p>
                            <strong>Nadia Castro</strong>
                        </div>
                        <div id="review2" class="content">
                            <p>“Controlar una flota de 75 dispositivos IoT nunca fué tan fácil cómo hasta ahora. Controlamos tanto el consumo cómo la facturación desde un único panel y sin necesidad de tener que ir cuadrando excels.”</p>
                            <strong>Andrés Peón</strong>
                        </div>
                    </div>
                    <div class="slide">
                        <div class="controls">
                            <a class="prev" href="#review1"></a>
                            <a class="next" href="#review2"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

.what__they__said .container { padding: 84px; margin: 0 auto; max-width: 1440px; }

.title { color: #1967AE; font-size: 30px; line-height: 36px; text-align: right; }

.key__reviews{ display: grid; grid-template-columns: 677px 418px; grid-gap: 100px; margin-top: 100px; }

@media (max-width: 1280px) {
    .key__reviews{grid-template-columns: 577px 318px; }
}
@media (max-width: 1080px) {
    .title { text-align: center; }
    .key__reviews { display: block; }
    .key__reviews .bkg__reviews { display: none !important; }
    .key__reviews .txt__reviews .content p { color: #18191F !important; }
}

.key__reviews .bkg__reviews { background: rgba(25, 103, 174, 0.18); border-radius:  0px 6px 6px 0px; height: 375px; display: flex; flex-wrap: nowrap; align-items: flex-end; justify-content: center; }
.key__reviews .txt__reviews { margin-top: 70px; overflow: hidden; }
.key__reviews .txt__reviews::before { content: ""; background-image: url("../../assets/images/bkg_quote.png"); width: 50px; height: 50px; display: inline-block; }
.key__reviews .txt__reviews .reviews { display: flex; overflow-x: auto; scroll-snap-type: x mandatory; scroll-behavior: smooth; -webkit-overflow-scrolling: touch; }
.key__reviews .txt__reviews .reviews::-webkit-scrollbar { width: 1px; height: 1px; }
.key__reviews .txt__reviews .reviews::-webkit-scrollbar-thumb { background: transparent; }
.key__reviews .txt__reviews .reviews::-webkit-scrollbar-track { background: transparent; }
.key__reviews .txt__reviews .reviews > div { scroll-snap-align: start; flex-shrink: 0; width: 100%; margin-right: 50px; transform-origin: center center; transform: scale(1); transition: transform 0.5s; position: relative; }
.key__reviews .txt__reviews .content p { margin-top: 26px; font-size: 14px; line-height: 28px; color: #C0CFDB; }
.key__reviews .txt__reviews .content strong { display: block; margin-top: 22px; font-size: 18px; line-height: 22px; color: #0F001A; font-weight: 800; }

.key__reviews .txt__reviews .slide .controls { list-style: none; margin-top: 34px; text-align: right; }
.key__reviews .txt__reviews .slide .controls a{ list-style: none; box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08); display: inline-block; width: 80px; height: 80px; text-align: center; }
.key__reviews .txt__reviews .slide .controls a.prev { background-color: #1967AE; border-radius: 4px 0px 0px 4px; cursor: pointer; }
.key__reviews .txt__reviews .slide .controls a.prev::after { content: ""; background: url("../../assets/icons/prev_icon.png"); display: inline-block; color: #FFFFFF; width: 16px; height: 28px; margin-top: 26px; }
.key__reviews .txt__reviews .slide .controls a.next { background-color: #FFFFFF; border-radius: 0 4px 4px 0; cursor: pointer }
.key__reviews .txt__reviews .slide .controls a.next::after { content: ""; background: url("../../assets/icons/next_icon.png"); display: inline-block; color: #1967AE; width: 16px; height: 28px; margin-top: 26px; }

@media (max-width: 550px) {
    .what__they__said .container { padding: 20px; }
    .title { font-size: 24px; }
    .key__reviews { margin-top: 20px; }
    .key__reviews .txt__reviews { margin-top: 30px }
    .key__reviews .txt__reviews .slide .controls a.prev::after,
    .key__reviews .txt__reviews .slide .controls a.next::after { margin-top: 6px; }
    .key__reviews .txt__reviews .slide .controls a { width: 40px; height: 40px; }
 }

</style>