<template>
    <ServiceComponent />
</template>

<script>  
// Imports VUE
import ServiceComponent from '../components/services/services';

export default {  
  name: "App",
  components: {
      ServiceComponent
  },
  mounted() {  
    document.title = "Covalent - Servicios";  
  }
};  
</script>